var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gift-card-container"},[_c('v-card',{staticClass:"gift-card default--text",class:_vm.mode,attrs:{"flat":_vm.mode == 'checkout'}},[_c('v-row',[_c('v-col',{attrs:{"cols":_vm.mode == 'full' ? 12 : 2,"sm":_vm.mode == 'full' ? 3 : 1}},[_c('router-link',{attrs:{"to":_vm.link,"aria-label":`Coupon ${_vm.name}`,"title":`Coupon ${_vm.name}`}},[(_vm.src)?_c('v-img',{staticClass:"rounded-sm",attrs:{"src":_vm.src,"alt":`Coupon ${_vm.name}`}}):_c('div',{staticClass:"certificate-card d-flex flex-column justify-space-around align-center",class:_vm.cssClass,attrs:{"outlined":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.handleClick.apply(null, arguments)}}},[(_vm.badgeLabel)?_c('div',{staticClass:"badge-tag"},[_vm._v(" "+_vm._s(_vm.badgeLabel)+" ")]):_vm._e(),(_vm.icon)?_c('v-icon',{attrs:{"alt":_vm.badgeLabel}},[_vm._v(" $"+_vm._s(_vm.icon.substring(0, _vm.icon.length - 4))+" ")]):(_vm.badgeValue)?_c('span',{staticClass:"d-block badge-value"},[_vm._v(" "+_vm._s(_vm.badgeValue)),_c('em',{staticClass:"small",domProps:{"innerHTML":_vm._s(_vm.badgeUnit)}})]):_vm._e()],1)],1)],1),_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":_vm.mode == 'full' ? 12 : 10,"sm":_vm.mode == 'full' ? 9 : 11}},[_c('div',{staticClass:"text-body-1 font-weight-bold",class:_vm.mode == 'full' ? 'text-body-1' : 'text-body-2'},[_vm._v(" "+_vm._s(_vm.name)+" ")]),(!_vm.hideEndDate && !_vm.showListAvailableCertificates)?_c('div',{staticClass:"expiration-date text-caption grey lighten-3 mb-2",domProps:{"innerHTML":_vm._s(
            `<span class='font-weight-bold text-uppercase'>${_vm.$t(
              'checkout.expires'
            )}</span>: ${_vm.$t('benefits.endDate')} 
              ${_vm.$dayjs(_vm.giftCertificate.endDate).format('DD/MM')}`
          )}}):_vm._e(),_c('div',{staticClass:"text-body-2 description",domProps:{"innerHTML":_vm._s(_vm.description)}}),(_vm.mode == 'full' && _vm.listUsedCertificates.length > 0)?_c('div',{staticClass:"text-body-2 mt-2"},[_vm._v(" Utilizzato in questi ordini: "),_vm._l((_vm.listUsedCertificates),function(used){return _c('v-chip',{key:used.orderId,staticClass:"mr-2",attrs:{"to":{ name: 'Order', params: { orderId: used.orderId } },"label":"","small":""}},[_c('span',{staticClass:"text-caption"},[_vm._v("#"+_vm._s(used.orderId))])])})],2):_vm._e(),(_vm.showListAvailableCertificates)?_c('div',{staticClass:"text-body-2 mt-2"},[_c('i',[_vm._v("Buoni utilizzabili:")]),_c('br'),_vm._l((_vm.listAvailableCertificates),function(available){return _c('div',{key:available.userGiftCertificateId,staticClass:"available-certificates-list py-1 d-flex"},[_c('div',{staticClass:"available-certificates-content py-1"},[_c('span',{staticClass:"text-body-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.name)),(available.amount)?[_vm._v(": "+_vm._s(_vm.$n(available.amount, "currency"))+" ")]:_vm._e()],2),(available.endDate)?_c('div',{staticClass:"expiration-date text-caption grey lighten-3",domProps:{"innerHTML":_vm._s(
                  `<span class='font-weight-bold text-uppercase'>${_vm.$t(
                    'checkout.expires'
                  )}</span>: ${_vm.$t('benefits.endDate')} ${_vm.$dayjs(
                    available.endDate
                  ).format('DD/MM')}`
                )}}):_vm._e()]),_c('v-spacer'),(available.cartId != _vm.cart.cartId)?_c('v-btn',{attrs:{"small":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.addUserGift(available.userGiftCertificateId)}}},[_vm._v(" "+_vm._s(_vm.$t("checkout.giftCodeUseNow"))+" ")]):_c('v-btn',{attrs:{"small":"","depressed":"","color":"primary","outlined":""},on:{"click":function($event){return _vm.remove(available.userGiftCertificateId)}}},[_vm._v(" "+_vm._s(_vm.$t("checkout.giftCodeRemove"))+" ")])],1)})],2):_vm._e(),_c('v-spacer'),_c('v-card-actions',{staticClass:"pa-0 mt-2 space-between align-center"},[(_vm.mode == 'full' && _vm.link)?_c('v-btn',{attrs:{"outlined":"","large":_vm.mode == 'full',"small":_vm.mode == 'checkout',"depressed":"","color":"primary","to":_vm.link}},[_vm._v(" "+_vm._s(_vm.btnLabel)+" ")]):_vm._e(),_c('v-spacer'),(_vm.isAssignable && _vm.userGiftCertificateId != null)?_c('v-btn',{attrs:{"large":_vm.mode == 'full',"small":_vm.mode == 'checkout',"depressed":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.remove(_vm.userGiftCertificateId)}}},[_vm._v(_vm._s(_vm.$t("checkout.giftCodeRemove"))+" ")]):(_vm.isAssignable)?_c('v-btn',{attrs:{"large":_vm.mode == 'full',"small":_vm.mode == 'checkout',"depressed":"","color":"primary"},on:{"click":function($event){return _vm.handleAdd()}}},[_vm._v(_vm._s(_vm.$t("checkout.giftCodeUseNow")))]):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }