<template>
  <div>
    <h2
      v-if="title"
      class="gift-list text-uppercase font-weight-bold default--text my-3"
    >
      {{ title }}
    </h2>
    <div v-for="gift in giftList" :key="gift.userGiftCertificateId">
      <GiftCard
        v-if="!isGiftExpired(gift)"
        :giftCertificate="gift"
        :mode="mode"
        class="mb-7"
        @add="$emit('reload')"
        @remove="$emit('reload')"
      />
    </div>
  </div>
</template>
<style lang="scss">
.gift-list {
  h2 {
    font-size: 20px;
  }
  .widget {
    min-height: 360px;
    background-size: contain;
  }
}
</style>
<script>
import GiftCard from "./GiftCard.vue";
export default {
  components: { GiftCard },
  name: "GiftList",
  props: {
    title: {
      type: String,
      required: false
    },
    giftList: {
      type: Array,
      default: () => []
    },
    mode: {
      type: String,
      default: "full"
    }
  },
  methods: {
    isGiftExpired(gift) {
      return this.$dayjs().isAfter(gift?.endDate, "day");
    }
  }
};
</script>
